import * as Msdyn365 from '@msdyn365-commerce/core';

export const getSecretValue = async (secretKey: string, baseUrl: string): Promise<string | undefined> => {
    return Msdyn365.msdyn365Commerce.secretManager?.getSecretValue(secretKey, baseUrl).then(secretValue => {
        if (secretValue && !secretValue.error && secretValue.value !== '') {
            return secretValue.value;
        }
        return undefined;
    });
};

/**
 * Centralised location for checkout generic data updates
 */
import { AffiliationLoyaltyTier } from '@msdyn365-commerce/retail-proxy';

interface AffiliationId {
    locale: string;
    affiliationId: number;
}
interface LoyaltyTierIds {
    locale: string;
    loyaltyTierIds: string;
}

/**
 * Generates the affiliationloyaltytiers for the current locale
 * @param locale
 * @param affiliationId
 * @param loyaltyTierIds
 * @returns AffiliationLoyaltyTier[]
 */
export const getAffiliation = (
    locale: string,
    affiliationIds: AffiliationId[],
    loyaltyTiersIds: LoyaltyTierIds[]
): AffiliationLoyaltyTier[] => {
    var affiliation: AffiliationLoyaltyTier[] = [];

    const affiliationId = affiliationIds?.find(item => {
        return item.locale === locale;
    })?.affiliationId;

    const loyaltyTiers = loyaltyTiersIds.find(item => {
        return item.locale === locale;
    })?.loyaltyTierIds;

    const loyaltyTierIds = loyaltyTiers?.split('|') ?? [];
    loyaltyTierIds.forEach(tier => {
        affiliation.push({
            AffiliationId: Number(affiliationId),
            LoyaltyTierId: Number(tier)
        });
    });

    return affiliation;
};

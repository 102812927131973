import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { OrgUnitLocationExtended } from './get-store-location-centiro.action';

export interface ICentiroDataAction {
    pickUpLocation?: OrgUnitLocationExtended;
    deliveryMode?: string;
}

export class CentiroDataActionInput implements IActionInput {
    public getCacheKey = () => `CentiroDataAction`;
    public getCacheObjectType = () => 'CentiroDataAction';
    public dataCacheType = (): CacheType => 'request';
}

export async function selectedModalAction(input: CentiroDataActionInput, ctx: IActionContext): Promise<ICentiroDataAction> {
    return { pickUpLocation: undefined, deliveryMode: undefined };
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new CentiroDataActionInput();
};

export default createObservableDataAction<ICentiroDataAction>({
    action: <IAction<ICentiroDataAction>>selectedModalAction,
    id: 'SetStoreLocationCentiro',
    input: createInput
});
